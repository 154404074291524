<template>
  <div class="container">
    <h4>Lista firm</h4> <button @click="addRow" class="add-button">Dodaj pozycje</button>
    <!-- <h3>{{ content }}</h3> -->
    <ul class="responsive-table">
      <li class="table-header">
        <div class="col col-1">Id</div>
        <div class="col col-3">Nazwa</div>
        <div class="col col-2">Miejscowość</div>
        <div class="col col-1">Kod pocztowy</div>
        <div class="col col-2">Adres</div>
        <div class="col col-O">Operacje</div>

      </li>
      <li class="table-row" v-for="(cont, index) in content" :key="cont.id">
        <div class="col col-1" data-label="Id">{{ cont.id }}</div>
        <!-- <div v-if="cont.id === 1">
            <img src="..//static/images/img1.png" alt="" />
          </div>
          <div v-else-if="cont.id === 2">
              <img src="..//static/images/img2.png" alt="" />
          </div>
          <div v-else-if="cont.id === 3">
            <img src="..//static/images/img3.png" alt="" />
          </div>
          <div v-else>
            <img src="..//static/images/img4.png" alt="" />
          </div>  -->
        <div class="col col-3" data-label="Nazwa">{{ cont.name }}</div>
        <div class="col col-2" data-label="Miejscowość">{{ cont.city }}</div>
        <div class="col col-1" data-label="Kod pocztowy">{{ cont.code }}</div>
        <div class="col col-2" data-label="Adres">{{ cont.street }}</div>
        <div class="col col-O">
          <button class="delete-button" @click="deleteRow(index)">Usuń</button>
          <button class="edit-button" @click="editRow(row)">Edytuj</button>
          <!-- <button class="save-button" @click="saveRow(row)">Save</button> -->
        </div>
      </li>
    </ul>
  </div>
  <!-- <table class="table table-bordered">
    <thead>
      <tr>
        <h4><th scope="col">id</th></h4>
        <th scope="col">Nazwa</th>
        <th scope="col">Miasto</th>
        <th scope="col">Ulica</th>
        <th scope="col">Kod pocztowy</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="cont in content" :key="cont.id">
        <td>{{cont.id}}</td>
        <td>{{cont.name}}</td>
        <td>{{cont.city}}</td>
        <td>{{cont.street}}</td>
        <td>{{cont.code}}</td>
      </tr>
    </tbody>
  </table> -->
</template>

<script>
import UserService from "../services/user.service";

export default {
  name: "Company",
  methods: {
    // getFormattedDate(date) {
    //   if (!date) {
    //     return null
    //   }
    //   return moment(date).format("YYYY-MM-DD")
    // },
    editRow(cont) {
      cont.editing = true;
    },
    saveRow(cont) {
      cont.editing = false;
    },
    deleteRow(index) {
      this.content.splice(index, 1);
    },
    addRow() {
      this.content.push({ id: '', name: '', city: '', code: '', street: '', editing: true });
    }
  },
  data() {
    return {
      content: "",
    };
  },
  mounted() {
    UserService.getCompany().then(
      (response) => {
        this.content = response.data;
      },
      (error) => {
        this.content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );
  },
};
</script>

<style>
.container {
  max-width: 1600px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 10px;
  padding-right: 10px;
}

h2 {
  font-size: 26px;
  margin: 20px 0;
  text-align: center;
}

h2 small {
  font-size: 0.5em;
}

.responsive-table {
  padding: 0px 0px;
}

.responsive-table li {
  border-radius: 3px;
  padding: 25px 30px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
}

.responsive-table .table-header {
  background-color: #000000;
  color: #ffffff;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 0.03em;
}

.responsive-table .table-row {
  background-color: #ffffff;
  box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.1);
}

.responsive-table .col-1 {
  flex-basis: 10%;
}

.responsive-table .col-2 {
  flex-basis: 20%;
}

.responsive-table .col-3 {
  flex-basis: 30%;
}

.responsive-table .col-4 {
  flex-basis: 40%;
}

.responsive-table .col-O {
  flex-basis: 15%;
}

button {
  display: inline-block;
  font-size: 14px;
  font-weight: bold;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 4px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  transition: all 0.3s ease-in-out;
}

.edit-button {
  background-color: #2196F3;
  color: white;
}

.edit-button:hover {
  background-color: #1976D2;
}

.save-button {
  background-color: #4CAF50;
  color: white;
}

.save-button:hover {
  background-color: #388E3C;
}

.delete-button {
  background-color: #f44336;
  color: white;
}

.delete-button:hover {
  background-color: #C62828;
}

.add-button {
  margin-top: 1rem;
  background-color: #4CAF50;
  color: white;
}

.add-button:hover {
  background-color: #3e8e41;
}

@media all and (max-width: 767px) {
  button {
    width: 90px;
    height: 50px;
  }

  .delete-button {
    position: absolute;
  }

  .responsive-table .table-header {
    display: none;
  }

  .responsive-table li {
    display: block;
  }

  .responsive-table .col {
    flex-basis: 100%;
  }

  .responsive-table .col {
    display: flex;
    padding: 10px 0;
  }

  .responsive-table .col:before {
    color: #6C7A89;
    padding-right: 10px;
    content: attr(data-label);
    flex-basis: 50%;
    text-align: right;
  }
}</style>
